import React from 'react';
import { useAppDispatch } from '../../app/hooks';
import { RenderMeta, NodeMatrix } from '../../types';
import { maybeToggleEdge } from '../../features/play/gameSlice';
import { openEdgeLineModal } from '../../features/build/buildSlice';
import { calculatePosition } from '../../util/geometry';
import { colors, ColorName } from '../../colors'

const EdgeLine = (props: { 
  nodeMatrix: NodeMatrix, 
  source: string, 
  target: string, 
  clickHandler: (e: React.MouseEvent<SVGLineElement, MouseEvent>) => void,
  renderMeta: RenderMeta 
}) => {

  const nodeMatrix: NodeMatrix = props.nodeMatrix;
  const source: string = props.source;
  const target: string = props.target;
  const colorName = nodeMatrix[source]['edges'][target] as ColorName;
  const colorCode = colors[colorName];
  
  const startPosition = calculatePosition(nodeMatrix[source].coordinates.x, nodeMatrix[source].coordinates.y, props.renderMeta);
  const endPosition   = calculatePosition(nodeMatrix[target].coordinates.x, nodeMatrix[target].coordinates.y, props.renderMeta);

  return (
    <line
      x1={startPosition.x}
      y1={startPosition.y}
      x2={endPosition.x}
      y2={endPosition.y}
      stroke={colorCode}
      strokeWidth="20"
      className='clickable'
      onClick={props.clickHandler}
    />
  );
}

export const GameEdgeLine = (props: { nodeMatrix: NodeMatrix, source: string, target: string, renderMeta: RenderMeta  }) => {
  const dispatch = useAppDispatch();

  const source: string = props.source;
  const target: string = props.target;

  const handleEdgeClick = () => {
    dispatch(maybeToggleEdge({ A: source, B: target }));
  }

  return (
    <EdgeLine
      nodeMatrix={props.nodeMatrix}
      source={props.source}
      target={props.target}
      clickHandler={handleEdgeClick}
      renderMeta={props.renderMeta}
    />
  );
}

export const BuilderEdgeLine = (props: { nodeMatrix: NodeMatrix, source: string, target: string, renderMeta: RenderMeta }) => {
  const dispatch = useAppDispatch();

  const source: string = props.source;
  const target: string = props.target;

  const handleEdgeClick = (e: React.MouseEvent<SVGLineElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(openEdgeLineModal({ source: source, target: target }));
  }

  return (
    <EdgeLine
      nodeMatrix={props.nodeMatrix}
      source={props.source}
      target={props.target}
      clickHandler={handleEdgeClick}
      renderMeta={props.renderMeta}
    />
  );
}

export default GameEdgeLine;