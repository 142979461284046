export const getRandomLetter = (): string => {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';
  return alphabet.charAt(Math.floor(Math.random() * alphabet.length));
}

export const generateRandomString = (length: number = 3): string => {
  let result = '';
  for (let i = 0; i < length; i++) {
      result += getRandomLetter();
  }
  return result;
}