import React from 'react';
import LogoImg from '../img/logos/puzzed-logo-color.svg';
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <div className='fixed top-0 left-0 right-0 flex justify-between p-4'>
      <Link to={'/'} className='block'>
        <img src={LogoImg} style={{width: '6rem'}} />
      </Link>
    </div>
  );
}

export default Header;