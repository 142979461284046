import { render } from '@testing-library/react';
import { RenderMeta } from '../types';

export const calculatePosition = (x: number, y: number, renderMeta: RenderMeta) => {
  const width = renderMeta.canvas.width;
  const height = renderMeta.canvas.height;
  const padding = renderMeta.canvas.padding;
  const rows = renderMeta.board.rows;
  const columns = renderMeta.board.columns;

  const xPos = ( x - 1 ) * (width - (2 * padding)) / (columns - 1) + padding;
  const yPos = ( y - 1 ) * (height - (2 * padding)) / (rows - 1) + padding;
  // const xPos = ( x - 1 ) * (width - (2 * padding)) / (rows - 1) + padding;
  // const yPos = ( y - 1 ) * (height - (2 * padding)) / (columns - 1) + padding;

  const result = { x: xPos, y: yPos };

  console.log('calculatePosition result:', result, renderMeta);

  return result;
}
export default calculatePosition;