import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GamePiece, NodeMatrix, GameBoardState, RestEndpointState } from '../../types';

import config from '../../config';
import MockGameData from '../../mockData/MockPuzzleData';

export type GameState = {
  gameBoard: GameBoardState;
  api: {
    initialFetch: RestEndpointState
  }
}

const initialState: GameState = {
  gameBoard: {
    board: {
      rows: 0,
      columns: 0,
    },
    nodes: {},
    pieces: [],
  },
  api: {
    initialFetch: {
      status: 'idle',
      error: null,
    }
  }
};

// SHOW | GET /puzzles/:id
export const fetchInitialGameBoard = createAsyncThunk(
  'game/fetchInitialGameBoard', 
  async (puzzleId: string) => {
    const path = `${config.apiRoot}/puzzed/v0/puzzles/${puzzleId}`
    const response = await fetch(path, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })

    return await response.json()
  }
)

// CREATE | POST /puzzles
export const createPuzzle = createAsyncThunk('build/createPuzzle', async (args: GameBoardState) => {
  const path = `${config.apiRoot}/puzzed/v0/puzzles`
  const apiArgs = { puzzle: { content: args } }

  const response = await fetch(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(apiArgs),
  })

  return await response.json()
})

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    maybeToggleEdge: (state, action) => {
			const data = action.payload;

      const gamePieces = state.gameBoard.pieces;
      let gamePieceA: GamePiece | null = null
      let gamePieceB: GamePiece | null = null
      const color = state.gameBoard.nodes[data.A].edges[data.B];
      gamePieceA = gamePieces.find((piece) => piece.node === data.A ) || null;
      gamePieceB = gamePieces.find((piece) => piece.node === data.B ) || null;
      
      if (gamePieceA && !gamePieceB) { 
        if (gamePieceA.color === color) {
          gamePieceA.node = data.B;
        }
      } else if (!gamePieceA && gamePieceB) { 
        if (gamePieceB.color === color) {
          gamePieceB.node = data.A;
        }
      }
		},
    importGameBoard: (state, action) => {
      const data = action.payload;
      state.gameBoard = data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitialGameBoard.pending, (state) => {
        state.api.initialFetch.status = 'pending';
      })
      .addCase(fetchInitialGameBoard.fulfilled, (state, action) => {
        const data = action.payload;
        if (data.puzzle?.content) {
          state.api.initialFetch.status = 'success';
          state.gameBoard = data.puzzle?.content;
        } else {
          state.api.initialFetch.status = 'error';
          if (data.error) {
            state.api.initialFetch.error = data.error;
          }
        }
      })
      .addCase(fetchInitialGameBoard.rejected, (state) => {
        state.api.initialFetch.status = 'error';
      });
    }
});

export const { 
  maybeToggleEdge,
  importGameBoard
} = gameSlice.actions;
export default gameSlice.reducer;
