import React from 'react';
import { Outlet, Link } from "react-router-dom";
import MinimalFooter from '../partials/MinimalFooter';
import { Header } from '../partials/Header';

import LogoImg from '../img/logos/puzzed-logo-color.svg';

export const Play = () => {
  return (
    <div>
      <Header />
      <Outlet />
      {/* <MinimalFooter /> */}
    </div>
  );
}

export default Play;