import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { RenderMeta } from '../../types';
import { openGridPointModal } from '../../features/build/buildSlice';
import { calculatePosition } from '../../util/geometry';

export const BuilderGridCircle = (props: { x: number, y: number, renderMeta: RenderMeta }) => {
  const dispatch = useAppDispatch();

  const position = calculatePosition(
    props.x,
    props.y,
    props.renderMeta
  )
  const r = 10; // Radius of the grid point
  
  const handleGridPointClick = (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(openGridPointModal({ x: props.x, y: props.y }));
  }

  return (
    <circle
      cx={position.x}
      cy={position.y}
      r={r}
      fill='#dad8d5'
      className='clickable'
      onClick={handleGridPointClick}
    />
  )
}
export const BuilderGrid = (props: { renderMeta: RenderMeta }) => {
  const rows    = props.renderMeta.board.rows;
  const columns = props.renderMeta.board.columns;

  const [grid, setGrid] = useState<Array<Array<number>>>([]);

  useEffect(() => {
    const newGrid: number[][] = [];
    for (let i = 0; i < rows; i++) {
      newGrid.push([]);
      for (let j = 0; j < columns; j++) {
        newGrid[i].push(0);
      }
    }
    setGrid(newGrid);
  }, [rows, columns]);

  return (
    <g>
      {grid.map((row, rowIndex) => (
        row.map((_, columnIndex) => (
          <BuilderGridCircle
            key={`${rowIndex}-${columnIndex}`}
            x={columnIndex + 1}
            y={rowIndex + 1}
            renderMeta={props.renderMeta} 
          />
        ))
      ))}
    </g>
  )
}
export default BuilderGrid;