import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FullPuzzleInfo, PuzzleMeta, RestEndpointState } from '../../types';

import config from '../../config';

// import MockGameData from '../../mockData/OldMockGameData';
// import MockBrowsingData from '../../mockData/MockBrowsingData';

export type BrowsingState = {
  puzzles: FullPuzzleInfo[];
  api: {
    initialFetch: RestEndpointState
  }
}

const initialState: BrowsingState = {
  puzzles: [],
  api: {
    initialFetch: {
      status: 'idle',
      error: null,
    }
  }
};

export const fetchPuzzles = createAsyncThunk(
  'browse/fetchPuzzles', 
  async ({ offset = 0, length = 10 }: { offset?: number | undefined, length?: number | undefined }) => {
    // const puzzles: PuzzleSummary[] = await MockBrowsingData;
    // return puzzles.slice(offset, (offset + length));
    const path = `${config.apiRoot}/puzzed/v0/puzzles`
    const response = await fetch(path, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })

    return await response.json()
  }
)
export const fetchInitialGameBoard = createAsyncThunk(
  'game/fetchInitialGameBoard', 
  async (puzzleId: string) => {
    const path = `${config.apiRoot}/puzzed/v0/puzzles/${puzzleId}`
    const response = await fetch(path, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })

    return await response.json()
  }
)

const browseSlice = createSlice({
  name: 'browse',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPuzzles.pending, (state) => {
        state.api.initialFetch.status = 'pending';
      })
      .addCase(fetchPuzzles.fulfilled, (state, action) => {
        state.api.initialFetch.status = 'success';
        const data = action.payload;
        state.puzzles = data;
      })
      .addCase(fetchPuzzles.rejected, (state) => {
        state.api.initialFetch.status = 'error';
      });
    }
});

export default browseSlice.reducer;