import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { Link } from 'react-router-dom';

import {
  PuzzleMeta, 
  GameBoardState, CanvasMeta, RenderMeta, 
} from '../../types'

import { fetchPuzzles } from './browseSlice';

import GameEdgeLine from '../../components/puzzles/GameEdgeLine';
import GameNodeCircle from '../../components/puzzles/GameNodeCircle';

// import Level1Img from '../../img/puzzles/level-1.svg';

export const PuzzleGraphic = ({ board, renderMeta }: { board: GameBoardState, renderMeta: RenderMeta }) => {
  const nodes = board.nodes;
  const height = renderMeta.canvas.height || 400;
  const width = renderMeta.canvas.width || 400;
  return (
    <svg viewBox={`0 0 ${height} ${width}`}>
      {/* Render edges */}
      {Object.keys(nodes).map(source => 
        Object.keys(nodes[source]['edges']).map(target => {
          if (nodes[source]['edges'][target]) {
            return ( <GameEdgeLine renderMeta={renderMeta} nodeMatrix={nodes} source={source} target={target} key={`edge-${source + target}`} /> )
          } else {
            return null;
          }
        })
      )}
      {/* Render nodes */}
      {Object.keys(nodes).map(nodeKey => (
        <GameNodeCircle renderMeta={renderMeta}  node={nodes[nodeKey]} key={`node-${nodeKey}`} />
      ))}
    </svg>
  );
}

export const PuzzleThumbnail = ({ puzzleMeta, board, renderMeta }: { puzzleMeta: PuzzleMeta, board: GameBoardState, renderMeta: RenderMeta }) => {
  const { title, key } = puzzleMeta;
  return (
    <Link to={`/play/${key}`} className='block w-[33vw] md:w-[25vw] lg:w-[20-vw]'>
      <div>
        <PuzzleGraphic board={board} renderMeta={renderMeta}/>
      </div>
      <div>{title ? title : key}</div>
    </Link>
  );
}

export const PuzzleIndex = () => {
  const dispatch = useAppDispatch();
  const loadStatus: string = useAppSelector(state => state.browsing.api.initialFetch.status)
  
  const puzzleThumbnails = useAppSelector(state => state.browsing.puzzles);
  // const boardMeta: BoardMeta = useAppSelector((state: RootState) => state.game.gameBoard.board);
  
  const [canvasMeta, setCanvasMeta] = useState<CanvasMeta>({
    width: 400, // Width of the SVG canvas
    height: 400, // Height of the SVG canvas
    padding: 35, // Internal padding within the edges of the SVG canvas
  });

  useEffect(() => {
    if (loadStatus === 'idle') {
      dispatch(fetchPuzzles({}));
    }
  }, [loadStatus, dispatch])

  console.log('puzzleThumbnails: ')
  console.log(puzzleThumbnails)

  return (
    <div className='p-4'>
      <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-12 lg:gap-6'>
        { puzzleThumbnails.map((puzzle) => {
          const board: GameBoardState = puzzle.content; 
          const renderMeta = { board: puzzle.content.board, canvas: canvasMeta}
          const puzzleMeta: PuzzleMeta = puzzle.meta;
          return(
            <PuzzleThumbnail puzzleMeta={puzzle.meta} board={board} renderMeta={renderMeta} key={puzzle.meta.id} />
          )
        })} 
      </div>
    </div>
  );
}

export default PuzzleIndex;