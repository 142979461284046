import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useParams } from 'react-router-dom';

import { SubmittedGuess } from '../../types';

import { fetchInitialGameState, submitGuess } from './singlePlayerSlice';

import { hotColdColorByScore } from '../../colors';

// const shownHints = [
//   "It might come with a heart on top."
// ]

// const pastGuesses = [
//   { word: "PLEASE", letterScore: 2.3, semanticScore: 1.2 },
//   { word: "VALENTINE", letterScore: 4.7, semanticScore: 0.9 },
//   { word: "CHEST", letterScore: 2.3, semanticScore: 4.3 },
// ];

const ShownHint = (props: {
  content: string,
}) => {
  return (
    <li className="mb-8">&ldquo;{props.content}&rdquo;</li>
  )
}

const PastGuess = (props: { 
  word: string,
  letterScore: number,
  semanticScore: number,
}) => {
  const letterScoreColor =  hotColdColorByScore(props.letterScore, 10);
  const semanticScoreColor = hotColdColorByScore(props.semanticScore, 10);
  return (
    <div className="mb-4 grid grid-cols-2">
      <div className="flex flex-col justify-center text-gray-0 px-4 py-2 text-ellipsis overflow-hidden" style={{backgroundColor: "#373635", fontSize: "1.5rem"}}>
        &ldquo;{props.word}&rdquo;
      </div>
      <dl className="grid grid-rows-2">
        <div className="flex justify-between items-center text-gray-0 px-2 pt-1 pb-0.5"  style={{backgroundColor: semanticScoreColor}}>
          <dt>Semantic score (TODO)</dt>
          <dd className="text-xl">{props.semanticScore}</dd>
        </div>
        <div className="flex justify-between items-center text-gray-0 px-2 pb-1 pt-0" style={{backgroundColor: letterScoreColor}}>
          <dt>Letter score</dt>
          <dd className="text-xl">{props.letterScore}</dd>
        </div>
      </dl>
    </div>
  )
}

const GuessHistory = (props: { 
  pastGuesses: SubmittedGuess[],
}) => {
  const { pastGuesses } = props;
  const guessesHeadingWord = pastGuesses.length === 1 ? "Guess" : "Guesses";

  return (
    <>
      <div>
        <h2 className="text-xl my-4">{pastGuesses.length} {guessesHeadingWord}:</h2>
      </div>

      <div className="overflow-y-scroll">
        {pastGuesses.map((guess) => (
          <PastGuess
            word={guess.word}
            letterScore={guess.letterScore}
            semanticScore={guess.semanticScore}
            key={`guess-${guess.word}`}
          />
        ))}
      </div>
    </>
  )
}

const GuessForm = (props: { sessionKey: string }) => {
  const { sessionKey } = props;

  const dispatch = useAppDispatch();
  
  const [nextGuess, setNextGuess] = useState("");

  const handleNextGuessChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNextGuess(e.target.value)
	}
  
  const handleSubmitClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(submitGuess({ sessionKey: sessionKey, guess: nextGuess }));
		setNextGuess('');
	}

  return (
    <form>
      <input 
        className="p-1 my-2 text-center text-2xl border border-gray-50 rounded-sm w-full uppercase" 
        type="text" 
        name="nextGuess" 
        placeholder="guess"
        value={nextGuess} 
        required={true}
        onChange={handleNextGuessChange} 
      />
      <input 
        className="p-2 text-2xl border border-gray-50 rounded-sm w-full uppercase clickable" 
        type="submit" 
        value={nextGuess.length > 0 ? `Guess "${nextGuess}"` : "Enter a guess"} 
        disabled={nextGuess.length < 3}
        onClick={handleSubmitClick}
      />
    </form>
  )
}

const ShownHints = (props: { 
  shownHints: string[],
}) => {
  const { shownHints } = props;
  const hintsHeadingWord = shownHints.length === 1 ? "Hint" : "Hints"; 

  return (
    <div>
      <h2 className="text-xl">{hintsHeadingWord}:</h2>
      <ol className="text-xl">
        {shownHints.map((hint) => (
          <ShownHint content={hint} key={`hint-${hint}`} />
        ))}
      </ol>
    </div>
  )
}

export const SinglePlayer = (props: { 
}) => {
  const dispatch = useAppDispatch();
  
  const { sessionKey } = useParams();
  
  const loadStatus: string = useAppSelector(state => state.building.api.initialFetch.status)
  const shownHints = useAppSelector(state => state.singlePlayerWordGuessing.game.shownHints);
  const pastGuesses = useAppSelector(state => state.singlePlayerWordGuessing.game.pastGuesses);
  
  useEffect(() => {
    if (loadStatus === 'idle' && sessionKey) {
      dispatch(fetchInitialGameState(sessionKey));
    }
  }, [loadStatus, sessionKey, dispatch])

  return (
    <div className="max-w-md mx-auto grid grid-rows-2 h-full">
      <div className="flex flex-col justify-between">
        <div>&nbsp;</div>

        <div className="">
          <h1 className="text-xl">Try to guess the secret word!</h1>
        </div>

        <ShownHints shownHints={shownHints} />        

        <div>
          {sessionKey && <GuessForm sessionKey={sessionKey} />}
        </div>
      </div>

      <div className="flex flex-col">
        {pastGuesses.length > 0 && <GuessHistory pastGuesses={pastGuesses} />}
      </div>
    </div>
  )
}

export default SinglePlayer;