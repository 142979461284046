import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { 
  GraphNode, NodeMatrix, GamePiece, 
  GameBoardState, 
  BoardMeta, CanvasMeta, RenderMeta, 
  Modal, GameObjectHandle,
} from '../../types'
import { RootState } from '../../app/store';
import { 
  fetchInitialGameBoard, 
  addNode, removeNode, activateNodeConnectionMode, 
  addEdge, removeEdge, setEdgeColor,
  addGamePiece, removeGamePiece, setGamePieceColor, 
  increaseGridSize, decreaseGridSize, moveGameBoard,
  openGridPointModal, openNodeCircleModal, openEdgeLineModal, openEdgeLineColorModal, openGamePieceModal, openGamePieceColorModal, 
  resetUiState, getActiveColors, openEditGridMenu, closeEditGridMenu 
} from './buildSlice';

import { ColorName, getAvailableColorsFromActiveColors } from '../../colors'
import { calculatePosition } from '../../util/geometry';

export const AbstractModal = (props: { modalMeta: Modal, renderMeta: RenderMeta }) => {
  switch (props.modalMeta.type) {
    case 'gridPoint':
      return <GridPointModal modalMeta={props.modalMeta} renderMeta={props.renderMeta} />
    case 'nodeCircle':
      return <NodeCircleModal modalMeta={props.modalMeta} renderMeta={props.renderMeta} />
    case 'edgeLine':
      return <EdgeLineModal modalMeta={props.modalMeta} renderMeta={props.renderMeta} />
    case 'edgeLineColor':
      return <EdgeLineColorModal modalMeta={props.modalMeta} renderMeta={props.renderMeta} />
    case 'gamePiece':
      return <GamePieceModal modalMeta={props.modalMeta} renderMeta={props.renderMeta} />
    case 'gamePieceColor':
      return <GamePieceColorModal modalMeta={props.modalMeta} renderMeta={props.renderMeta} />
    default:
      return null;
  }
}
export const GridPointModal = (props: { modalMeta: Modal, renderMeta: RenderMeta }) => {
  const dispatch = useAppDispatch();
  const [x, y] = props.modalMeta.anchor.split('-').map(Number); 
  const position = calculatePosition(x, y, props.renderMeta);
  const modalWrapperStyle: React.CSSProperties = {
    top: position.y, 
    left: position.x
  }
  
  const handleAddNodeClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(addNode({ x: x, y: y }));
  }
  return (
    <div style={modalWrapperStyle} className='modal-wrapper'>
      <div className='modal'>
        <button className='modal-button' onClick={handleAddNodeClick}>
          Add node 
        </button>
      </div>
    </div>
  )
}

export const NodeCircleModal = (props: { modalMeta: Modal, renderMeta: RenderMeta }) => {
  const dispatch = useAppDispatch();
  const [x, y] = props.modalMeta.anchor.split('-').map(Number); 
  const position = calculatePosition(x, y, props.renderMeta);
  
  const modalWrapperStyle: React.CSSProperties = {
    top: position.y, 
    left: position.x
  }
  
  const handleAddPieceClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(addGamePiece({ x: x, y: y }));
  }
  const handleDeleteNodeClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(removeNode({ x: x, y: y }));
  }
  const handleAddEdgeClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const handle: GameObjectHandle = {
      objectType: 'GraphNode',
      objectId: `${x}-${y}`,
    }
    dispatch(activateNodeConnectionMode(handle));
  }
  return (
    <div style={modalWrapperStyle} className='modal-wrapper'>
      <div className='modal'>
        <button className='modal-button' onClick={handleDeleteNodeClick}>
          Delete node 
        </button>
        <button className='modal-button' onClick={handleAddEdgeClick}>
          Add edge
        </button>
        <button className='modal-button' onClick={handleAddPieceClick}>
          Add game piece 
        </button>
      </div>
    </div>
  )
}
export const EdgeLineModal = (props: { modalMeta: Modal, renderMeta: RenderMeta }) => {
  const dispatch = useAppDispatch();
  const [src, trg] = props.modalMeta.anchor.split('-');
  const sourceNode = useAppSelector((state: RootState) => state.building.puzzle.content.nodes[src]);
  const targetNode = useAppSelector((state: RootState) => state.building.puzzle.content.nodes[trg]);

  const sourcePosition = calculatePosition(sourceNode.coordinates.x, sourceNode.coordinates.y, props.renderMeta);
  const targetPosition = calculatePosition(targetNode.coordinates.x, targetNode.coordinates.y, props.renderMeta);

  const top = (sourcePosition.y + targetPosition.y) / 2;
  const left = (sourcePosition.x + targetPosition.x) / 2;

  const modalWrapperStyle: React.CSSProperties = {
    top: top, 
    left: left 
  }
  
  const handleChangeColorClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(openEdgeLineColorModal({ source: src, target: trg }));
  }
  const handleDeleteEdgeClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(removeEdge({ source: src, target: trg }));
  }
  return (
    <div style={modalWrapperStyle} className='modal-wrapper'>
      <div className='modal'>
        <button className='modal-button' onClick={handleDeleteEdgeClick}>
          Delete edge
        </button>
        <button className='modal-button' onClick={handleChangeColorClick}>
          Change color 
        </button>
      </div>
    </div>
  )
}

export const EdgeLineColorModal = (props: { modalMeta: Modal, renderMeta: RenderMeta }) => {
  const dispatch = useAppDispatch();
  const [src, trg] = props.modalMeta.anchor.split('-');
  const sourceNode = useAppSelector((state: RootState) => state.building.puzzle.content.nodes[src]);
  const targetNode = useAppSelector((state: RootState) => state.building.puzzle.content.nodes[trg]);

  const sourcePosition = calculatePosition(sourceNode.coordinates.x, sourceNode.coordinates.y, props.renderMeta);
  const targetPosition = calculatePosition(targetNode.coordinates.x, targetNode.coordinates.y, props.renderMeta);

  const top = (sourcePosition.y + targetPosition.y) / 2;
  const left = (sourcePosition.x + targetPosition.x) / 2;

  const activeColors = useAppSelector(getActiveColors);
  const selectableColors = getAvailableColorsFromActiveColors(activeColors as ColorName[]);
  
  const modalWrapperStyle: React.CSSProperties = {
    top: top, 
    left: left 
  }
  
  const handleColorClick = (color: string) => {
    return ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      console.log(`Color clicked: ${color}`);
      dispatch(setEdgeColor({ source: src, target: trg, color: color }));
    })
  }
  
  return (
    <div style={modalWrapperStyle} className='modal-wrapper'>
      <div className='modal modal-grid'>
        {selectableColors.map(color => 
          <button className='modal-button' onClick={handleColorClick(color)} key={`color-${color}`}>{color}</button>
        )}
      </div>
    </div>
  )
}

export const GamePieceModal = (props: { modalMeta: Modal, renderMeta: RenderMeta }) => {
  const dispatch = useAppDispatch();
  const nodeKey = props.modalMeta.anchor;
  const node = useAppSelector((state: RootState) => state.building.puzzle.content.nodes[nodeKey]);
  const position = calculatePosition(node.coordinates.x, node.coordinates.y, props.renderMeta);
  
  const modalWrapperStyle: React.CSSProperties = {
    top: position.y, 
    left: position.x
  }
  
  const handleChangeColorClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(openGamePieceColorModal({ nodeKey: nodeKey }));
  }
  const handleDeletePieceClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(removeGamePiece({ nodeKey: nodeKey }));
  }
  return (
    <div style={modalWrapperStyle} className='modal-wrapper'>
      <div className='modal'>
        <button className='modal-button' onClick={handleDeletePieceClick}>
          Delete game piece 
        </button>
        <button className='modal-button' onClick={handleChangeColorClick}>
          Change color 
        </button>
      </div>
    </div>
  )
}

export const GamePieceColorModal = (props: { modalMeta: Modal, renderMeta: RenderMeta }) => {
  const dispatch = useAppDispatch();
  const nodeKey = props.modalMeta.anchor;
  const node = useAppSelector((state: RootState) => state.building.puzzle.content.nodes[nodeKey]);
  const position = calculatePosition(node.coordinates.x, node.coordinates.y, props.renderMeta);
  
  const modalWrapperStyle: React.CSSProperties = {
    top: position.y, 
    left: position.x
  }

  const activeColors = useAppSelector(getActiveColors);
  const selectableColors = getAvailableColorsFromActiveColors(activeColors as ColorName[]);
  
  const handleColorClick = (color: string) => {
    return ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      console.log(`Color clicked: ${color}`);
      dispatch(setGamePieceColor({ nodeKey: nodeKey, color: color }));
    })
  }
  
  return (
    <div style={modalWrapperStyle} className='modal-wrapper'>
      <div className='modal modal-grid'>
        {selectableColors.map(color => 
          <button className='modal-button' onClick={handleColorClick(color)} key={`color-${color}`}>{color}</button>
        )}
      </div>
    </div>
  )
}