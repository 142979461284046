import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

import { GameBoardState } from '../../types'
import { RootState } from '../../app/store';
import { importGameBoard } from '../play/gameSlice';
import { 
  togglePlayMode, increaseGridSize, decreaseGridSize, moveGameBoard, openEditGridMenu, closeEditGridMenu,
  createPuzzle 
} from './buildSlice';

import LogoImg from '../../img/logos/puzzed-logo-color.svg';
import { Link } from "react-router-dom";

export const ToggleModeButton = () => {
  const uiMode: string = useAppSelector((state: RootState) => state.building.ui.mode) || "standard";
  const dispatch = useAppDispatch();

  const gameBoard: GameBoardState = useAppSelector((state: RootState) => state.building.puzzle.content);

  const buttonText = uiMode === "play" ? "Back to edit mode" : "Play this puzzle";

  const handleToggleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (uiMode !== "play") {
      dispatch(importGameBoard(gameBoard));
    }
    dispatch(togglePlayMode());
  }

  return (
    <button className='menu-button' onClick={handleToggleClick}>{buttonText}</button>
  )
}

export const GridMenu = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state: RootState) => state.building.ui.menus.editGrid.open);

  const handleOpenClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(openEditGridMenu());
  }
  const handleCloseClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(closeEditGridMenu());
  }

  return (
    <>
      {isOpen ? 
        <button className='menu-button' onClick={handleCloseClick}>Close menu</button> :
        <button className='menu-button' onClick={handleOpenClick}>Edit grid</button>
      }
      {isOpen ? <OpenGridMenu /> : ''}
    </>
  )
}
export const ClosedGridMenu = () => {}
export const OpenGridMenu = () => {
  const dispatch = useAppDispatch();

  const handlePlusClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(increaseGridSize());
  }
  const handleMinusClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(decreaseGridSize());
  }
  const handleMoveClick = (direction: string) => {
    return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      dispatch(moveGameBoard({ direction: direction }));
    }
  }
  return (
    <div className='menu absolute top-24 z-10 px-4 text-xl'>
      <div className='grid grid-cols-2 py-4'>
        <button className='menu-button' onClick={handleMinusClick}>-</button>
        <button className='menu-button' onClick={handlePlusClick}>+</button>
      </div>
      <div className='grid grid-cols-3 py-4'>
        <button className='menu-button noop'>&nbsp;</button>
        <button className='menu-button' onClick={handleMoveClick('up')}>&uarr;</button>
        <button className='menu-button noop'>&nbsp;</button>

        <button className='menu-button' onClick={handleMoveClick('left')}>&larr;</button>
        <button className='menu-button noop'>&nbsp;</button>
        <button className='menu-button' onClick={handleMoveClick('right')}>&rarr;</button>
        
        <button className='menu-button noop'>&nbsp;</button>
        <button className='menu-button' onClick={handleMoveClick('down')}>&darr;</button>
        <button className='menu-button noop'>&nbsp;</button>
      </div>
    </div>
  )
}

export const SaveButton = () => {
  
  const dispatch = useAppDispatch();

  const gameBoard: GameBoardState = useAppSelector((state: RootState) => state.building.puzzle.content);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(createPuzzle(gameBoard));
  }

  return (
    <button className='menu-button' onClick={handleClick}>Save puzzle</button>
  )
}

export const BuildMenu = () => {
  const uiMode: string = useAppSelector((state: RootState) => state.building.ui.mode) || "standard";
  
  return (
    <div className='nav-menu fixed top-0 left-0 right-0 flex justify-between p-4'>
      <div>
        <Link to={'/'} className='block'>
          <img src={LogoImg} style={{width: '6rem'}} />
        </Link>
      </div>

      <div>
        <GridMenu />
        <ToggleModeButton />
        {(uiMode !== "play") ? <SaveButton /> : ''}
      </div>
    </div>
  );
}

export default BuildMenu;