import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useParams } from 'react-router-dom';

import { fetchInitialGameBoard } from './gameSlice';
import { RootState } from '../../app/store';
import { 
  NodeMatrix, GamePiece, 
  GameBoardState, 
  BoardMeta, CanvasMeta, RenderMeta, 
  RestEndpointStatus, RestEndpointState, 
} from '../../types'

import { GameNodeCircle } from '../../components/puzzles/GameNodeCircle';
import { GameEdgeLine } from '../../components/puzzles/GameEdgeLine';
import { GamePieceCircle } from '../../components/puzzles/GamePieceCircle';

export const GameLoadError = () => {
  const errorMessage: string | null = useAppSelector((state: RootState) => state.game.api.initialFetch.error);
  return (
    <div className='text-2xl'>
      <h1 className='mb-4'>Game Load Error</h1>
      <p className='mb-4'>¯\_(ツ)_/¯</p>
      {errorMessage && <p className='mb-4'>{errorMessage}</p>}
    </div>
  );
}

export const Game = () => {
  const gameState: GameBoardState = useAppSelector((state: RootState) => state.game.gameBoard);
  const nodeMatrix: NodeMatrix = gameState.nodes;
  const gamePieces: GamePiece[] = gameState.pieces;
  // const boardMeta: BoardMeta = useAppSelector((state: RootState) => state.game.gameBoard.board);
  const boardMeta: BoardMeta = gameState.board;
  const rowCount = boardMeta.rows;
  
  const [canvasMeta, setCanvasMeta] = useState<CanvasMeta>({
    width: 400, // Width of the SVG canvas
    height: 400, // Height of the SVG canvas
    padding: 35, // Internal padding within the edges of the SVG canvas
  });
  
  const [renderMeta, setRenderMeta] = useState<RenderMeta>({
    board: boardMeta,
    canvas: canvasMeta,
  })

  return (
    <div className='fullSquareBox p-8'>
      {/* <svg width={canvasMeta.width} height={canvasMeta.height}> */}
      <svg className='w-full h-full' viewBox={`0 0 400 400`}>
        {/* Render edges */}
        {rowCount > 0 && Object.keys(nodeMatrix).map(source => 
          Object.keys(nodeMatrix[source]['edges']).map(target => {
            if (nodeMatrix[source]['edges'][target]) {
              return ( <GameEdgeLine renderMeta={renderMeta} nodeMatrix={nodeMatrix} source={source} target={target} key={`edge-${source + target}`} /> )
            } else {
              return null;
            }
          })
        )}
        {/* Render nodes */}
        {rowCount > 0 && Object.keys(nodeMatrix).map(nodeKey => (
          <GameNodeCircle renderMeta={renderMeta}  node={nodeMatrix[nodeKey]} key={`node-${nodeKey}`} />
        ))}
        {/* Render game pieces */}
        {rowCount > 0 && gamePieces.map(piece=> (
          <GamePieceCircle renderMeta={renderMeta} gameState={gameState} gamePiece={piece} key={`piece-${piece.id}`} />
        ))}
      </svg>
    </div>
  );
}

export const GameZone = () => {
  const { gameId } = useParams();
  const gId = gameId ? gameId : 'chatGpt';

  const dispatch = useAppDispatch();
  const loadStatus: RestEndpointStatus = useAppSelector(state => state.game.api.initialFetch.status) || 'idle';
  
  useEffect(() => {
    if (loadStatus === 'idle') {
      dispatch(fetchInitialGameBoard(gId));
    }
  }, [loadStatus, dispatch])
  
  return (
    {
      'idle': <div>Loading...</div>,
      'pending': <div>Loading...</div>,
      'success': <Game />,
      'error': <GameLoadError />,
    }[loadStatus]
  );
}

export default GameZone;
