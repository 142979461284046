import React from 'react';
import { Outlet, Link } from "react-router-dom";
import { GuessticleHeader } from '../partials/GuessticleHeader';

export const Guess = () => {
  return (
    <>
      <GuessticleHeader />
      <Outlet />
    </>
  );
}

export default Guess;