// This file contains:
// 1. Set API base URL
// 2. Set API keys for 3rd party services e.g: Google Maps
// 3. Meta data defaults

const origin = document.location.origin
const hostname = document.location.hostname

// const isProduction = process.env.NODE_ENV === 'production'
// const isLocal = !isProduction

const isLocal = hostname === 'localhost' || hostname === 'lvh.me' 
const isProduction = !isLocal

const domain = isLocal ? 'lvh.me' : 'puzzed.com'

const websiteRoot = isLocal ? `http://${domain}:3001` : `https://${domain}`
// const websocketRoot = isLocal ? `ws://${domain}:3000` :   `wss://api.${domain}`
const apiRoot = isLocal ? `http://api.${domain}:3000` : `https://api.${domain}`

const appName = "Puzzed"

export const config = {
  origin: origin,
  apiRoot: apiRoot,
  websiteRoot: websiteRoot,
  // websocketRoot: websocketRoot,
  appName: appName,
  env: isProduction ? 'production' : 'development',
}

export default config