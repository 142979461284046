import React from 'react';
import LogoImg from '../img/logos/GuessticleLogo.svg';
import { Link } from "react-router-dom";

export const GuessticleHeader = () => {
  return (
    <div className='fixed top-0 left-0 right-0 flex justify-between p-4'>
      <Link to={'/'} className='block'>
        <img src={LogoImg} style={{width: '4rem'}} />
      </Link>
    </div>
  );
}

export default GuessticleHeader;