import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// Routes
import About from './routes/About';
import Play from './routes/Play';
import Build from './routes/Build';
import Browse from './routes/Browse';
import Guess from './routes/Guess';
import Meta from './routes/Meta';

// Landing / about pages
import { PreLaunchLandingPage } from './features/about/PreLaunchLandingPage';

// Spatial puzzles
import { GameZone } from './features/play/Game';
import { AbstractBoard } from './features/build/Board';
import { PuzzleIndex } from './features/browse/PuzzleIndex';

// Word puzzles
import { SinglePlayer } from './features/wordGuessing/SinglePlayer';

// Meta
import { Colors } from './features/meta/Colors';

import './style/layout.css';
import './App.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <About />,
    children: [
      { path: "/", element: <PreLaunchLandingPage /> },
    ],
  }, {
    path: "/play",
    element: <Play />,
    children: [
      { path: "/play/:gameId",   element: <GameZone /> },
    ]
  }, {
    path: "/build",
    element: <Build />,
    children: [
      { path: "/build/:gameId",   element: <AbstractBoard /> },
    ],
  }, {
    path: "/browse",
    element: <Browse />,
    children: [
      { path: "/browse", element: <PuzzleIndex /> },
    ],
  }, {
    path: "/guess",
    element: <Guess />,
    children: [
      { path: "/guess/:sessionKey", element: <SinglePlayer /> },
    ],
  }, {
    path: "/meta",
    element: <Meta />,
    children: [
      { path: "/meta/colors",   element: <Colors /> },
    ],
  },
]);

export function App() {
  return (
    <RouterProvider router={router} />
  )
}

export default App;