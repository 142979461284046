import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useParams } from 'react-router-dom';

import { NodeMatrix, GamePiece, GameBoardState, BoardMeta, CanvasMeta, RenderMeta, Modal } from '../../types'
import { RootState } from '../../app/store';
import { fetchInitialGameBoard, resetUiState, togglePlayMode } from './buildSlice';
import { importGameBoard } from '../play/gameSlice';

import { BuilderNodeCircle } from '../../components/puzzles/GameNodeCircle';
import { BuilderEdgeLine } from '../../components/puzzles/GameEdgeLine';
import { BuilderGamePieceCircle } from '../../components/puzzles/GamePieceCircle';
import { BuilderGrid } from '../../components/puzzles/BuilderGrid';
import { AbstractModal } from './Modals';
import { GridMenu } from './Menus';

import { BuildMenu } from './Menus';
import { Game } from '../play/Game';
import { time } from 'console';

export const AbstractBoard = () => {
  const uiMode: string = useAppSelector((state: RootState) => state.building.ui.mode) || "standard";

  return (
    <div>
      <BuildMenu />
      {uiMode === "play" ? <Game /> : <BuilderBoard />}
    </div>
  );
}

export const BuilderBoard = () => {
  const { gameId } = useParams();
  const gId = gameId ? gameId : 'chatGpt';

  const dispatch = useAppDispatch();
  const loadStatus: string = useAppSelector(state => state.building.api.initialFetch.status)

  const gameState: GameBoardState = useAppSelector((state: RootState) => state.building.puzzle.content);
  const boardMeta: BoardMeta = gameState.board;
  const nodeMatrix: NodeMatrix = gameState.nodes;
  const gamePieces: GamePiece[] = gameState.pieces;
 
  const openModals: Modal[] = useAppSelector((state: RootState) => state.building.ui.modals.open);

  const [canvasMeta, setCanvasMeta] = useState<CanvasMeta>({
    width: 400, // Width of the SVG canvas
    height: 400, // Height of the SVG canvas
    padding: 35, // Internal padding within the edges of the SVG canvas
  });
  
  const [renderMeta, setRenderMeta] = useState<RenderMeta>({
    board: boardMeta,
    canvas: canvasMeta,
  })
  
  const handleDocumentClick = (event: MouseEvent) => {
    // The target of the click event
    const target = event.target as Element;

    // Check if this target or any of its parents have a data-ignore-click attribute
    if (target.closest('[data-ignore-click]')) {
      // Ignore this click
      return;
    }

    // Handle the general click event here
    console.log('Document clicked:', event);
    dispatch(resetUiState());
  }
 
  // Uncomment if needed
  useEffect(() => {
    setRenderMeta({
      board: boardMeta,
      canvas: canvasMeta,
    })
  }, [canvasMeta, boardMeta])

  useEffect(() => {
    if (loadStatus === 'idle') {
      dispatch(fetchInitialGameBoard(gId));
    }
  }, [loadStatus, dispatch])

  useEffect(() => {
    // Attach the listener
    document.addEventListener('click', handleDocumentClick);

    // Clean up: remove the listener when the component is unmounted
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []); // Empty dependency array ensures this effect runs once when component mounts


  return (
    <div style={{position: 'relative'}}>
      {/* Render modals */}
      {openModals.map(modalData => (
        <AbstractModal modalMeta={modalData} renderMeta={renderMeta} key={`modal-${modalData.anchor}`} />
      ))}

      <div>
        {/* Render game board */}
        <svg width={canvasMeta.width} height={canvasMeta.height}>
          {/* Render grid */}
          <BuilderGrid renderMeta={renderMeta} />
          {/* Render edges */}
          {Object.keys(nodeMatrix).map(source => 
            Object.keys(nodeMatrix[source]['edges']).map(target => {
              if (nodeMatrix[source]['edges'][target]) {
                return ( <BuilderEdgeLine renderMeta={renderMeta} nodeMatrix={nodeMatrix} source={source} target={target} key={`edge-${source + target}`} /> )
              } else {
                return null;
              }
            })
          )}
          {/* Render nodes */}
          {Object.keys(nodeMatrix).map(nodeKey => (
            <BuilderNodeCircle renderMeta={renderMeta} node={nodeMatrix[nodeKey]} key={`node-${nodeKey}`} />
          ))}
          {/* Render game pieces */}
          {gamePieces.map(piece=> (
            <BuilderGamePieceCircle renderMeta={renderMeta} gameState={gameState} gamePiece={piece} key={`piece-${piece.id}`} />
          ))}
        </svg>
      </div>
    </div>
  );
}

export default AbstractBoard;