import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { 
  FullPuzzleInfo, GameObjectHandle, GamePiece, NodeMatrix, GameBoardState, 
  RestEndpointState, Modal 
} from '../../types';
import { RootState, AppThunk } from '../../app/store';

import config from '../../config';
import { generateRandomString } from '../../util/random';

import MockGameData from '../../mockData/MockPuzzleData';

export type GameEditingState = {
  // puzzle: FullPuzzleInfo | null;
  puzzle: FullPuzzleInfo;
  ui: {
    mode: string;
    activeObject: GameObjectHandle | null;
    modals: {
      open: Modal[];
    },
    menus: {
      editGrid: {
        open: boolean;
      }
    },
  }
  api: {
    initialFetch: RestEndpointState
    create: RestEndpointState
  }
}

const defaultUiState = {
  mode: 'standard',
  activeObject: null,
  modals: {
    open: [],
  },
  menus: {
    editGrid: {
      open: false
    }
  },
}

const initialState: GameEditingState = {
  puzzle: {
    meta: {
      id: '',
      key: '',
      title: '',
    },
    content: {
      board: {
        rows: 6,
        columns: 6,
      },
      nodes: {},
      pieces: [],
    },
  },
  ui: defaultUiState,
  api: {
    initialFetch: { status: 'idle', error: null },
    create:       { status: 'idle', error: null }
  }
};

// export const fetchInitialGameBoard = createAsyncThunk(
//   'build/fetchInitialGameBoard', 
//   async (puzzleId: string) => {
//     const gameBoard: GameBoardState = await MockGameData[puzzleId];
//     return gameBoard;
//   }
// )
// SHOW | GET /puzzles/:id
export const fetchInitialGameBoard = createAsyncThunk(
  'build/fetchInitialGameBoard', 
  async (puzzleId: string) => {
    console.log('fetchInitialGameBoard called')
    const path = `${config.apiRoot}/puzzed/v0/puzzles/${puzzleId}`
    const response = await fetch(path, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })

    return await response.json()
  }
)

export const createPuzzle = createAsyncThunk('build/createPuzzle', async (args: GameBoardState) => {
  const path = `${config.apiRoot}/puzzed/v0/puzzles`
  const apiArgs = { puzzle: { content: args } }

  const response = await fetch(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(apiArgs),
  })

  return await response.json()
})

export const getNodeKeyFromCoordinates = (state: GameEditingState, x: number, y: number) => {
  if (!state.puzzle) { return null; }
  const extantNodes = state.puzzle.content.nodes;
  const nodeKey = Object.keys(extantNodes).find((nodeKey) => {
    const node = extantNodes[nodeKey];
    return node.coordinates.x === x && node.coordinates.y === y;
  });
  return nodeKey;
}

const buildSlice = createSlice({
  name: 'build',
  initialState,
  reducers: {
    togglePlayMode: (state) => {
      if (state.ui.mode === 'play') {
        state.ui.mode = 'standard';
      } else {
        state.ui.mode = 'play';
      }
    },
    toggleEdge: (state, action) => {
      if (!state.puzzle) { return; }
			const data = action.payload;
      const gamePieces = state.puzzle.content.pieces;
      let gamePieceA: GamePiece | null = null
      let gamePieceB: GamePiece | null = null
      const color = state.puzzle.content.nodes[data.A].edges[data.B];
      gamePieceA = gamePieces.find((piece) => piece.node === data.A ) || null;
      gamePieceB = gamePieces.find((piece) => piece.node === data.B ) || null;
      
      if (gamePieceA && !gamePieceB) { 
        if (gamePieceA.color === color) {
          gamePieceA.node = data.B;
        }
      } else if (!gamePieceA && gamePieceB) { 
        if (gamePieceB.color === color) {
          gamePieceB.node = data.A;
        }
      }
		},
    addNode: (state, action) => {
      if (!state.puzzle) { return; }
      // TODO add check to make sure there is no node at the anchor
      // state.ui.modals.open = [];
      state.ui = { ...state.ui, ...defaultUiState };
      const newX = action.payload.x; 
      const newY = action.payload.y; 
      const extantNodes = state.puzzle.content.nodes;
      const extantNodeKeys = Object.keys(extantNodes).sort();
      const lastKey = extantNodeKeys[extantNodeKeys.length - 1] || 'A';
      const newKey = String.fromCharCode(lastKey.charCodeAt(0) + 1);
      const newNodes: NodeMatrix = Object.keys(extantNodes).reduce((nodes, nodeKey) => {
        const oldNode = extantNodes[nodeKey];
        const edges = { ...oldNode.edges, [newKey]: null };
        const newNodes: NodeMatrix = { ...nodes, [nodeKey]: { ...oldNode, edges} };
        return newNodes;
      }, {} as NodeMatrix);
      newNodes[newKey] = {
        coordinates: { x: newX, y: newY },
        edges: extantNodeKeys.reduce((edges, nodeKey) => {
          return { ...edges, [nodeKey]: null };
        }, {}) 
      }
      state.puzzle.content.nodes = newNodes;
    },
    removeNode: (state, action) => {
      // state.ui.modals.open = [];
      state.ui = { ...state.ui, ...defaultUiState };
      const delX = action.payload.x; 
      const delY = action.payload.y; 
      const extantNodes = state.puzzle.content.nodes;
      const extantNodeKeys = Object.keys(extantNodes).sort();
      const nixedNodeKey = Object.keys(extantNodes).find((nodeKey) => {
        const node = extantNodes[nodeKey];
        return node.coordinates.x === delX && node.coordinates.y === delY;
      });
      console.log('nixedNodeKey: ');
      console.log(nixedNodeKey);
      if (!nixedNodeKey) { return; }
      const newNodes: NodeMatrix = extantNodeKeys.reduce((nodes, nodeKey) => {
        if (nodeKey === nixedNodeKey) { return nodes; }
        const oldNode = extantNodes[nodeKey];
        const newEdges = { ...oldNode.edges };
        delete newEdges[nixedNodeKey];
        nodes[nodeKey] = { ...oldNode, edges: newEdges };
        return nodes;
      }, {} as NodeMatrix);
      state.puzzle.content.nodes = newNodes;
    },
    addEdge: (state, action) => {
      const targetNodeHandle: GameObjectHandle = action.payload;
      
      if (state.ui.activeObject?.objectType === 'GraphNode' && targetNodeHandle.objectType === 'GraphNode') {
        const sourceNodeHandle: GameObjectHandle = state.ui.activeObject;
        const [sourceX, sourceY] = sourceNodeHandle.objectId.split('-').map((coord) => parseInt(coord)); 
        const [targetX, targetY] = targetNodeHandle.objectId.split('-').map((coord) => parseInt(coord)); 
        
        if ((sourceX === targetX) && (sourceY === targetY)) { return; }
        
        const sourceKey = getNodeKeyFromCoordinates(state, sourceX, sourceY);
        const targetKey = getNodeKeyFromCoordinates(state, targetX, targetY);
        
        if (!sourceKey || !targetKey) { return; }

        const extantNodes = state.puzzle.content.nodes;
        Object.keys(extantNodes).map((nodeKey) => {
          const node = extantNodes[nodeKey];
          const edges = { ...node.edges };
          if (nodeKey === sourceKey) {
            edges[targetKey] = 'red';
          } else if (nodeKey === targetKey) {
            edges[sourceKey] = 'red';
          }
          extantNodes[nodeKey] = { ...node, edges };
        });
        state.ui = { ...state.ui, ...defaultUiState };
      } else {
        console.log('invalid edge');
        return
      }
    },
    removeEdge: (state, action) => {
      // state.ui.modals.open = [];
      const src = action.payload.source;
      const trg = action.payload.target;
      state.puzzle.content.nodes[src].edges[trg] = null;
      state.puzzle.content.nodes[trg].edges[src] = null;
      state.ui = { ...state.ui, ...defaultUiState };
    },
    setEdgeColor: (state, action) => {
      const src = action.payload.source;
      const trg = action.payload.target;
      state.puzzle.content.nodes[src].edges[trg] = action.payload.color;
      state.puzzle.content.nodes[trg].edges[src] = action.payload.color;
      // state.ui.modals.open = [];
      state.ui = { ...state.ui, ...defaultUiState };
    },
    addGamePiece: (state, action) => {
      const { x, y } = action.payload
      const nodeKey = getNodeKeyFromCoordinates(state, x, y);
      const newPiece = { id: generateRandomString(), color: 'red', node: nodeKey } as GamePiece;
      state.puzzle.content.pieces.push(newPiece);
      state.ui = { ...state.ui, ...defaultUiState };
    },
    removeGamePiece: (state, action) => {
      const nodeKey = action.payload.nodeKey;
      const newPieces = state.puzzle.content.pieces.filter((piece) => piece.node !== nodeKey);
      state.puzzle.content.pieces = newPieces;
      state.ui = { ...state.ui, ...defaultUiState };
    },
    setGamePieceColor: (state, action) => {
      const nodeKey = action.payload.nodeKey;
      const newColor = action.payload.color;
      const newPieces: GamePiece[] = state.puzzle.content.pieces.map((piece) => {
        if (piece.node === nodeKey) {
          piece.color = newColor;
        };
        return piece;
      });
      state.puzzle.content.pieces = newPieces;
      state.ui = { ...state.ui, ...defaultUiState };
    },
    increaseGridSize: (state) => {
      const curValue = state.puzzle.content.board.rows;
      state.puzzle.content.board.rows = curValue + 1;
      state.puzzle.content.board.columns = curValue + 1;
    },
    decreaseGridSize: (state) => {
      const curValue = state.puzzle.content.board.rows;
      state.puzzle.content.board.rows = curValue - 1;
      state.puzzle.content.board.columns = curValue - 1;
    },
    moveGameBoard: (state, action) => {
      const direction = action.payload.direction;
      const oldNodes = state.puzzle.content.nodes;
      let newNodes = oldNodes;
      Object.keys(oldNodes).forEach((nodeKey) => {
        const node = oldNodes[nodeKey];
        let newNode = { ...node };
        if (direction === 'up') {
          newNode = { ...node, coordinates: { ...node.coordinates, y: (node.coordinates.y - 1) } };
        } else if (direction === 'down') {
          newNode = { ...node, coordinates: { ...node.coordinates, y: (node.coordinates.y + 1) } };
        } else if (direction === 'left') {
          newNode = { ...node, coordinates: { ...node.coordinates, x: (node.coordinates.x - 1) } };
        } else if (direction === 'right') {
          newNode = { ...node, coordinates: { ...node.coordinates, x: (node.coordinates.x + 1) } };
        }
        newNodes[nodeKey] = newNode;
      });
      

      state.puzzle.content.nodes = newNodes;
    },
    resetUiState: (state) => {
      state.ui.modals.open = [];
      state.ui.mode = 'standard';
      state.ui.activeObject = null;
    },
    activateNodeConnectionMode: (state, action) => {
      state.ui.mode = 'nodeConnection';
      state.ui.activeObject = action.payload;
    },
    openNodeCircleModal: (state, action) => {
      const data = action.payload;
      const modal: Modal = {
        type: 'nodeCircle',
        anchor: `${data.x}-${data.y}`
      }
      state.ui.modals.open = [];
      state.ui.modals.open.push(modal);
    },
    openEdgeLineModal: (state, action) => {
      const data = action.payload;
      const modal: Modal = {
        type: 'edgeLine',
        anchor: `${data.source}-${data.target}`
      }
      state.ui.modals.open = [];
      state.ui.modals.open.push(modal);
    },
    openEdgeLineColorModal: (state, action) => {
      const data = action.payload;
      console.log('openEdgeLineColorModal');
      console.log('data: ');
      console.log(data);
      const modal: Modal = {
        type: 'edgeLineColor',
        anchor: `${data.source}-${data.target}`
      }
      state.ui.modals.open = [];
      state.ui.modals.open.push(modal);
    },
    openGridPointModal: (state, action) => {
      const data = action.payload;
      const modal: Modal = {
        type: 'gridPoint',
        anchor: `${data.x}-${data.y}`
      }
      state.ui.modals.open = [];
      state.ui.modals.open.push(modal);
    },
    openGamePieceModal: (state, action) => {
      const data = action.payload;
      const modal: Modal = {
        type: 'gamePiece',
        anchor: data.nodeKey 
      }
      state.ui.modals.open = [];
      state.ui.modals.open.push(modal);
    },
    openGamePieceColorModal: (state, action) => {
      const data = action.payload;
      const modal: Modal = {
        type: 'gamePieceColor',
        anchor: data.nodeKey 
      }
      state.ui.modals.open = [];
      state.ui.modals.open.push(modal);
    },
    openEditGridMenu: (state) => {
      state.ui.menus.editGrid.open = true;
    },
    closeEditGridMenu: (state) => {
      state.ui.menus.editGrid.open = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPuzzle.pending, (state) => {
        state.api.create.status = 'pending';
      })
      .addCase(createPuzzle.fulfilled, (state, action) => {
        state.api.create.status = 'success';
        const board = action.payload.puzzle.content as GameBoardState;
        state.puzzle.content = board;
      })
      .addCase(createPuzzle.rejected, (state) => {
        state.api.create.status = 'error';
      })
      .addCase(fetchInitialGameBoard.pending, (state) => {
        state.api.initialFetch.status = 'pending';
      })
      .addCase(fetchInitialGameBoard.fulfilled, (state, action) => {
        console.log('fetchInitialGameBoard.fulfilled')
        state.api.initialFetch.status = 'success';
        const response = action.payload;
        console.log(response)
        console.log(response.puzzle.content)
        console.log(response.puzzle.meta)
        state.puzzle.content = response.puzzle.content;
        state.puzzle.meta = response.puzzle.meta;
      })
      .addCase(fetchInitialGameBoard.rejected, (state) => {
        state.api.initialFetch.status = 'error';
      });
    }
});

export const getActiveColors = (state: RootState) => {
  console.log('getActiveColors');
  console.log(state);
  const activeColors: string[] = [];
  const gamePieces = state.building.puzzle.content.pieces;
  const nodes = state.building.puzzle.content.nodes;

  gamePieces.forEach((piece) => {
    const color = piece.color;
    if (!activeColors.includes(color)) {
      activeColors.push(color);
    }
  });
  Object.keys(nodes).forEach((nodeKey) => {
    const node = nodes[nodeKey];
    Object.keys(node.edges).forEach((edgeKey) => {
      const color = node.edges[edgeKey];
      if (color && !activeColors.includes(color)) {
        activeColors.push(color);
      }
    });
  });
  return activeColors;
}

export const { 
  toggleEdge,
  addNode,
  removeNode,
  activateNodeConnectionMode,
  addEdge,
  removeEdge,
  setEdgeColor,
  addGamePiece,
  removeGamePiece,
  setGamePieceColor,
  increaseGridSize,
  decreaseGridSize,
  moveGameBoard,
  resetUiState,
  openNodeCircleModal,
  openGridPointModal,
  openEdgeLineModal,
  openEdgeLineColorModal,
  openGamePieceModal,
  openGamePieceColorModal,
  openEditGridMenu,
  closeEditGridMenu,
  togglePlayMode,
} = buildSlice.actions;
export default buildSlice.reducer;
