
export type ColorName = "red" | "green" | "blue" | "yellow" | "purple" | "orange" | "brown" | "pink" | "gray" | "black" | "white" | "lime";

export const availableColors: ColorName[] = [
  "red",    "green",   "blue", 
  "orange", "purple",  "yellow", 
  "pink",   "lime",    "black", 
];

export const colors = {
  "red":    "#ec293f",
  "green":  "#19a497", 
  "blue":   "#2a73ad",
  // "orange": "#f55e27",
  // "orange": "#f59527",
  "orange": "#f58427",
  // "purple": "#582d8e", 
  // "purple": "#393a83", 
  "purple": "#908ffe",
  // "yellow": "#f1e11f", 
  "yellow": "#f8bf01",
  "brown":  "#6c4624",
  // "pink":   "#e15187",
  "pink":   "#f7aead",
  "gray":   "#8a8298",
  "black":  "#313233",
  "white":  "#ffffff",
  "lime":   "#abcc3f",
  "twilight": "#908ffe",
  "lightPurple": "#908ffe",
}

export const colorsDarkened = {
  "red":    "#34050a",
  "green":  "#022b27", 
  "blue":   "#061a2a",
  "yellow": "#3c2e00", 
  "purple": "#212152", 
  "orange": "#492303",
  "pink":   "#372120",
  "lime":   "#303b0e",
  "black":  "#000000",
  
  "brown":  "#6c4624",
  "gray":   "#8a8298",
  "white":  "#ffffff",
  "twilight": "#908ffe",
  "lightPurple": "#908ffe",
}

export const colorVariants = {
  "red":    [ "#ec293f" ],
  "green":  [ "#19a497" ], // #1ca699
  "blue":   [ "#2a73ad" ],
  "yellow": [ "#f1e11f", "#f8bf01" ],
  "purple": [ "#393a83", "#582d8e", "#908ffe" ],
  "orange": [ "#f55e27" ],
  "brown":  [ "#6c4624" ],
  "pink":   [ "#f7aead", "#e15187" ],
  "gray":   [ "#8a8298" ],
  "black":  [ "#313233" ],
  "white":  [ "#ffffff" ],
  "lime":   [ "#abcc3f" ],
}

export const hotColdColorSpectrum = [
  "#3D3D3Dff",
  "#3C3F41ff",
  "#3C4145ff",
  "#3B4349ff",
  "#3A444Cff",
  "#3A4650ff",
  "#394854ff",
  "#384A58ff",
  "#384C5Cff",
  "#374E60ff",
  "#365064ff",
  "#365167ff",
  "#35536Bff",
  "#34556Fff",
  "#345773ff",
  "#335977ff",
  "#335B7Bff",
  "#325D7Fff",
  "#315F83ff",
  "#316086ff",
  "#30628Aff",
  "#2F648Eff",
  "#2F6692ff",
  "#2E6896ff",
  "#2D6A9Aff",
  "#2D6C9Eff",
  "#2C6DA1ff",
  "#2B6FA5ff",
  "#2B71A9ff",
  "#2A73ADff",
  "#3170A9ff",
  "#376EA5ff",
  "#3E6BA2ff",
  "#45699Eff",
  "#4B669Aff",
  "#526496ff",
  "#596192ff",
  "#605F8Fff",
  "#665C8Bff",
  "#6D5987ff",
  "#745783ff",
  "#7A547Fff",
  "#81527Cff",
  "#884F78ff",
  "#8E4D74ff",
  // "#954A70ff",
  "#9C486Dff",
  // "#A24569ff",
  "#A94365ff",
  // "#B04061ff",
  "#B63D5Dff",
  // "#BD3B5Aff",
  "#C43856ff",
  // "#CB3652ff",
  // "#D1334Eff",
  "#D8314Aff",
  // "#DF2E47ff",
  // "#E52C43ff",
  "#EC293Fff",
];

export const hotColrColorSpectrumIndex = (score: number, max: number) => {
  return Math.floor(score * hotColdColorSpectrum.length / max);
}

export const hotColdColorByScore = (score: number, max: number) => {
  return hotColdColorSpectrum[hotColrColorSpectrumIndex(score, max)]
}

export const getAvailableColorsFromActiveColors = (activeColors: ColorName[]) => {
  const activeColorSet = new Set(activeColors);
  const inactiveColors = availableColors.filter(c => !activeColorSet.has(c));
  const modulo = activeColors.length % 3;

  // Calculate how many more items we need to make the length a multiple of 3.
  let additionalItemsNeeded = 0;
  if (modulo == 0) {
      additionalItemsNeeded = 3; // if already a multiple of 3, add 3 more items
  } else {
      additionalItemsNeeded = 3 - modulo; // otherwise, add the required number to make it a multiple of 3
  }

  // Use slice to get the required number of items from difference.
  const itemsToAdd = inactiveColors.slice(0, additionalItemsNeeded);
  const selectableColors = [...activeColors, ...itemsToAdd];

  return selectableColors;
};