import React from 'react';
import { useAppDispatch } from '../../app/hooks';
import { RenderMeta, NodeMatrix, GamePiece, GameBoardState } from '../../types';
import { openGamePieceModal } from '../../features/build/buildSlice';
import { calculatePosition } from '../../util/geometry';
import { colors, colorsDarkened, ColorName } from '../../colors'

export const GamePieceCircle = (props: { 
  renderMeta: RenderMeta, 
  gameState: GameBoardState, 
  gamePiece: GamePiece 
  onClick?: (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => void,
}) => {
  const nodeMatrix: NodeMatrix = props.gameState.nodes;
  const gamePiece: GamePiece = props.gamePiece;
  
  const position = calculatePosition(
    nodeMatrix[gamePiece.node].coordinates.x,
    nodeMatrix[gamePiece.node].coordinates.y,
    props.renderMeta
  )

  const colorName = gamePiece.color as ColorName;
  const fillColorCode = colors[colorName];
  const strokeColorCode = colorsDarkened[colorName];

  return (
    <circle
      cx={position.x}
      cy={position.y}
      r="30"
      fill={fillColorCode}
      stroke={strokeColorCode}
      strokeWidth="5"
      className={ props.onClick ? 'gamePiece clickable' : 'gamePiece'}
      onClick={props.onClick}
    />
  )
}

export const BuilderGamePieceCircle = (props: { renderMeta: RenderMeta, gameState: GameBoardState, gamePiece: GamePiece }) => {
  const dispatch = useAppDispatch();

  const gamePiece: GamePiece = props.gamePiece;
  
  const handleClick = (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(openGamePieceModal({ nodeKey: gamePiece.node  }));
  }

  return (
    <GamePieceCircle
      renderMeta={props.renderMeta}
      gameState={props.gameState}
      gamePiece={props.gamePiece}
      onClick={handleClick}
    />
  )
}

export default GamePieceCircle;