import React, { useState, useEffect } from 'react';
import { colors, colorsDarkened, ColorName, availableColors } from '../../colors'

export const ColorSwatch = ({ colorName, showOutlines }: { colorName: ColorName, showOutlines: boolean }) => {
  const hexStandard = colors[colorName];
  const hexDarkened = colorsDarkened[colorName];

  const styles = {
    backgroundColor: hexStandard,
    border: (showOutlines ? `0.5rem solid ${hexDarkened}` : 'none'),
    width: '8rem',
  }

  return (
    <div className='aspect-square flex flex-col justify-center text-gray-0' style={styles}>
      {colorName}
    </div>
  )
}

export const Colors = () => {
  const [showOutlines, setShowOutlines] = useState(false);

  const toggleShowOutlines = () => {
    setShowOutlines(!showOutlines);
  }

  return (
    <div style={{}}>
      <div className='text-right mb-4'>
        <input type="checkbox" onClick={toggleShowOutlines} /> Show outlines
      </div>
      
      <div className='grid grid-cols-3 gap-2'>
        {availableColors.map(colorName => 
          <ColorSwatch 
            colorName={colorName as ColorName}
            showOutlines={showOutlines} 
            key={`color-${colorName}`} 
          />)
        }
      </div>
    </div>
  );
}

export default Colors;