import React from 'react';
import { Outlet } from "react-router-dom";
import MinimalFooter from '../partials/MinimalFooter';
import { Header } from '../partials/Header';

export const Build = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
}

export default Build;