import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
// Spatial reasoning game
import gameReducer from '../features/play/gameSlice';
import buildingReducer from '../features/build/buildSlice';
import browseReducer from '../features/browse/browseSlice';

// Word guessing game
import singlePlayerWordGuessingReducer from '../features/wordGuessing/singlePlayerSlice'; 

export const store = configureStore({
  reducer: {
    game: gameReducer,
    building: buildingReducer,
    browsing: browseReducer,
    singlePlayerWordGuessing: singlePlayerWordGuessingReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
