import React from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { RenderMeta, GraphNode, GameObjectHandle } from '../../types';
import { addEdge, openNodeCircleModal } from '../../features/build/buildSlice';
import { calculatePosition } from '../../util/geometry';

// Play
export const GameNodeCircle = (props: { 
  node: GraphNode, 
  renderMeta: RenderMeta, 
  onClick?: (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => void, 
}) => {
  const [x, y] = [props.node.coordinates.x, props.node.coordinates.y];
  const position = calculatePosition(x, y, props.renderMeta)
  const className = props.onClick ? 'clickable' : ''
  return (
      <circle
        cx={position.x}
        cy={position.y}
        r="20"
        stroke='black'
        strokeWidth="5"
        fill="white"
        className={className}
        onClick={props.onClick}
      />
  )
}

// Build
export const BuilderNodeCircle = (props: { node: GraphNode, renderMeta: RenderMeta }) => {
  const dispatch = useAppDispatch();
  
  const uiMode: string = useAppSelector(state => state.building.ui.mode)

  const [x, y] = [props.node.coordinates.x, props.node.coordinates.y];
  const handle: GameObjectHandle = {
    objectType: 'GraphNode',
    objectId: `${x}-${y}`,
  } 

  const handleNodeCircleClick = (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
    e.stopPropagation();
    console.log(`Node circle clicked: (${handle.objectId})`);
    if (uiMode === 'nodeConnection') { 
      dispatch(addEdge(handle)); 
    } else {
      dispatch(openNodeCircleModal({ x: x, y: y }));
    }
  }
  
  return (
    <GameNodeCircle
      renderMeta={props.renderMeta} 
      node={props.node} 
      onClick={handleNodeCircleClick} 
    />
  )
}
export default GameNodeCircle;