import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SinglePlayerWordGuessingGameState, RestEndpointState } from '../../types';

import config from '../../config';

export type SinglePlayerWordGuessingState = {
  sessionMeta: {
    id?: string;
    key?: string;
  },
  game: SinglePlayerWordGuessingGameState;
  api: {
    initialFetch: RestEndpointState
  }
}

const initialState: SinglePlayerWordGuessingState = {
  sessionMeta: {},
  game: {
    shownHints: [
      // "It might come with a heart on top."
    ],
    pastGuesses: [
      // { word: "PLEASE", letterScore: 2.3, semanticScore: 1.2 },
      // { word: "VALENTINE", letterScore: 4.7, semanticScore: 0.9 },
      // { word: "CHEST", letterScore: 2.3, semanticScore: 4.3 },
    ],
  },
  api: {
    initialFetch: {
      status: 'idle',
      error: null,
    }
  }
};

// SHOW | GET /single_player/v0/game_sessions/:id 
export const fetchInitialGameState = createAsyncThunk(
  'singlePlayerWordGuessing/fetchInitialGameState', 
  async (sessionKey: string) => {
    const path = `${config.apiRoot}/single_player/v0/game_sessions/${sessionKey}`
    const response = await fetch(path, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })

    return await response.json()
  }
)

// CREATE | POST /single_player/v0/game_sessions/:key/turn 
export const submitGuess = createAsyncThunk(
  'singlePlayerWordGuessing/submitGuess', 
  async (args: { sessionKey: string, guess: string }) => {
    const path = `${config.apiRoot}/single_player/v0/game_sessions/${args.sessionKey}/turn`
    const apiArgs = { game_turn: { submission_str: args.guess } }

    const response = await fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(apiArgs),
    })

    return await response.json()
  }
)

const singlePlayerWordGuessingSlice = createSlice({
  name: 'singlePlayerWordGuessing',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitialGameState.pending, (state) => {
        state.api.initialFetch.status = 'pending';
      })
      .addCase(fetchInitialGameState.fulfilled, (state, action) => {
        state.api.initialFetch.status = 'success';
        const data = action.payload;
        state.game = data.game;
        state.sessionMeta = data.sessionMeta;
      })
      .addCase(fetchInitialGameState.rejected, (state) => {
        state.api.initialFetch.status = 'error';
      })
      .addCase(submitGuess.fulfilled, (state, action) => {
        // state.api.initialFetch.status = 'success';
        const data = action.payload;
        state.game.pastGuesses.unshift(data);
      })
    }
});

export default singlePlayerWordGuessingSlice.reducer;
