import React from 'react'
import LogoImg from '../../img/logos/puzzed-logo-color.svg';


export const PreLaunchLandingPage = () => {
  return (
    <div className="p-3 flex flex-col justify-center" style={{height: '100vh', width: '100vw'}}>
      <div className="mb-4 flex justify-center">
        <img src={LogoImg} style={{maxHeight: '75vh'}} />
      </div>

      <div className="uppercase text-3xl mb-6" style={{letterSpacing: '0.5vw', lineHeight: 1}}>
        Launching soon!
      </div>
    </div>
  )
}

